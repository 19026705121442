.rainbow-button {
    background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%);
    animation: slidebg 30s linear infinite;
}

.rainbow-button:after {
    content: attr(alt);
    display: flex;
}

@keyframes slidebg {
    to {
        background-position: 20vw;
    }
}